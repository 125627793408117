<template>
  <v-container>
    <v-form v-model="isFormValid" ref="form">
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">Nowe hasło*</h3>
          <v-text-field
            v-model="new_password"
            :append-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="validators.passwordRules"
            @click:append="show_new_password = !show_new_password"
            :type="show_new_password ? 'text' : 'password'"
            name="new_password"
            @change="validatePassword"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">Powtórz hasło*</h3>
          <v-text-field
            v-model="confirmation_password"
            :append-icon="show_confirmation_password ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show_confirmation_password = !show_confirmation_password"
            :rules="[
              ...validators.passwordRules,
              (value) => validators.samePasswordRule(value, new_password),
            ]"
            :type="show_confirmation_password ? 'text' : 'password'"
            name="confirmation_password"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <primary-button :onClick="emitClick" :loading="loading">{{ buttonText }}</primary-button>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import { validators } from '../validators/Validators';

export default {
  components: { PrimaryButton },
  name: 'ChangePassword',
  props: ['buttonText'],
  data() {
    return {
      isFormValid: false,
      new_password: '',
      confirmation_password: '',
      show_new_password: false,
      show_confirmation_password: false,
      loading: false,
    };
  },
  methods: {
    emitClick() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        this.$emit('click', this.new_password);
      }
    },
    validatePassword() {
      this.$refs.form.validate();
    },
  },
  computed: {
    validators() {
      return validators;
    },
  },
};
</script>
<style lang="scss" scoped></style>
