<template>
  <title-wrapper title="Aktywuj konto i ustaw hasło">
    <v-container class="white pa-10">
      <set-new-account-password
        button-text="Aktywuj konto"
        :loading="loading"
        v-on:click="activateAccount"
      >
      </set-new-account-password>
    </v-container>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../components/TitleWrapper.vue';
import SetNewAccountPassword from '../../components/SetNewAccountPassword.vue';
import AuthService from '../../services/AuthService';
export default {
  components: { TitleWrapper, SetNewAccountPassword },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async activateAccount(password) {
      this.loading = true;
      try {
        const userId = this.$route.params.userId;
        await AuthService.activateUser(userId, password);
        this.$router.push({ name: 'ActivationEnd', query: { success: true } });
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'ActivationEnd', query: { success: false } });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
